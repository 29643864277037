import React, { forwardRef, Ref, SVGProps } from "react";

const BrandIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="159"
      height="44"
      viewBox="0 0 159 44"
      fill="none"
    >
      <path d="M46 12.7124V30.3141H42V12.7124H46Z" fill="#FBFBFF" />
      <path
        d="M57 30.3141V12.7124H63.6994C65.723 12.7124 67.3047 13.0729 68.4444 13.794C69.5841 14.5151 70.1551 15.5211 70.1574 16.8122C70.1687 17.7244 69.8124 18.6098 69.1535 19.3063C68.4454 20.0504 67.5056 20.5891 66.4539 20.8537V20.8905C67.8437 21.0498 68.9486 21.512 69.7685 22.277C70.1738 22.6548 70.4911 23.1009 70.7023 23.5893C70.9134 24.0776 71.0141 24.5986 70.9984 25.122C70.9984 26.7 70.3658 27.9589 69.1004 28.8985C67.8351 29.8382 66.1416 30.3085 64.0198 30.3095L57 30.3141ZM60.8114 15.3322V19.9483H62.8824C63.9101 19.9483 64.7129 19.7302 65.2908 19.2941C65.8698 18.862 66.1593 18.2492 66.1593 17.4556C66.1593 16.0369 65.027 15.3276 62.7624 15.3276L60.8114 15.3322ZM60.8114 22.5773V27.6959H63.4322C64.5536 27.6959 65.4273 27.465 66.0531 27.0034C66.6789 26.5417 66.9906 25.8998 66.9883 25.0776C66.9883 23.4107 65.7584 22.5773 63.2986 22.5773H60.8114Z"
        fill="#FBFBFF"
      />
      <path
        d="M96 30.3141H91.813L88.8423 25.6644C88.2417 24.7226 87.7301 24.1047 87.3073 23.8106C86.9147 23.5244 86.4329 23.3682 85.9366 23.3663H84.6163V30.3141H81V12.7124H87.3415C91.6558 12.7124 93.813 14.2955 93.813 17.4617C93.8364 18.5604 93.453 19.6325 92.7285 20.4937C92.0054 21.3516 90.9756 21.9593 89.639 22.3168V22.3643C90.4943 22.636 91.4374 23.5409 92.4683 25.0791L96 30.3141ZM84.6179 15.3674V20.7005H86.7756C87.7577 20.7005 88.5425 20.4314 89.1301 19.8931C89.4174 19.6335 89.6439 19.3202 89.7952 18.9732C89.9464 18.6263 90.0189 18.2534 90.0081 17.8785C90.0081 16.2034 88.9501 15.3664 86.8341 15.3674H84.6179Z"
        fill="#FBFBFF"
      />
      <path
        d="M116 30.3141H105V12.7124H115.568V15.5467H108.677V20.031H115.079V22.853H108.677V27.4936H116V30.3141Z"
        fill="#FBFBFF"
      />
      <path
        d="M136 30.3141H126V12.7124H135.613V15.5467H129.345V20.031H135.168V22.853H129.345V27.4936H136V30.3141Z"
        fill="#FBFBFF"
      />
      <path
        d="M158.995 15.5467H153.394V30.3141H149.589V15.5467H144V12.7124H159L158.995 15.5467Z"
        fill="#FBFBFF"
      />
      <path
        d="M15.2381 21.5133L34 36.3634L28.351 43.0265L8.91436 27.642L8.20071 27.0797L1.16831 21.5133L0 20.5886V0.199343H8.91436V15.3845L28.351 0L34 6.66314L15.2381 21.5133Z"
        fill="#FBFBFF"
      />
      <path
        d="M7.3609 35.6015L7.31509 35.5664L7.15946 35.4565L4.56144e-07 30.3141V38.8342C-0.00034101 39.5405 0.191037 40.2354 0.556353 40.8544C0.921668 41.4733 1.44907 41.9963 2.08955 42.3746C2.73004 42.753 3.46282 42.9744 4.21982 43.0184C4.97683 43.0624 5.73348 42.9274 6.41949 42.6262C7.1055 42.3249 7.69861 41.867 8.1437 41.2951C8.5888 40.7232 8.87144 40.0558 8.96536 39.355C9.05929 38.6541 8.96145 37.9425 8.68094 37.2863C8.40043 36.63 7.94636 36.0505 7.3609 35.6015Z"
        fill="url(#paint0_linear_14_991)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14_991"
          x1="4.5"
          y1="30.3141"
          x2="4.5"
          y2="43.0265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4824D" />
          <stop offset="1" stopColor="#F59467" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const ForwardRef = forwardRef(BrandIcon);
export default ForwardRef;
