import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { cookieStore } from "../../cookieStore";
import { useApp } from "../../hooks/useApp";
import { useTranslator } from "../../hooks/useTranslator";
import { redirectToRoute } from "../../lib";
import BrandIcon from "../Ui/Icons/homePage/BrandIcon";
import cx from "clsx";
import TruckIconSVG from "../Ui/Icons/homePage/TruckIcon";

export const AppHeader: React.FC<any> = ({
  isFixed = true,
}: {
  isFixed: boolean;
}) => {
  const [navbar, setNavbar] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const { setLang, pathname } = useApp();
  const router = useRouter();
  const { locales, locale: activeLocale } = router;
  const { __T } = useTranslator();

  const otherLocales = (locales || []).filter(
    (locale) => locale !== activeLocale
  );
  const dirPath = pathname?.split("/")?.[1]?.toLowerCase() || "ar";

  useEffect(() => {
    setLang(dirPath);
    if (window.location.pathname === "/") {
      router.push(`/${dirPath}`, undefined, { shallow: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirPath]);
  const onLanguageButtonClick = () => {
    const language: string = activeLocale === "ar" ? "en" : "ar";
    const currentUrl: any =
      window.location.pathname.split(
        `/${activeLocale === "ar" ? "ar" : "en"}/`
      )?.[1] || "";
    const newUrl = `/${language}/`.concat(currentUrl, window.location.search);
    setLang(language);
    cookieStore.setCookie("lang", language);
    redirectToRoute(newUrl, true);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      style={{ background: "linear-gradient(90deg, #40179B 0%, #6C1FCF 100%)" }}
      className={cx(
        "w-full top-0 ltr:left-0 rtl:right-0",
        isFixed ? "isFixed" : "bg-gray-25",
        isScrolled ? "bg-white z-10 shadow-md" : "",
        navbar ? "" : ""
      )}
    >
      <div className="w-full bg-white">
        <div className="container mx-auto py-2 text-[#40179B] text-center">
          <Link href={`/${activeLocale}/centers`} className="underline">
            {__T("Check nearest service centers")}{" "}
          </Link>
        </div>
      </div>
      <div className="justify-between px-4 mx-auto lg:max-w-full md:items-center md:flex md:px-36">
        <div>
          <div className="flex items-center justify-between py-3 md:py-3 md:block">
            <Link href="/">
              <BrandIcon width={130} color="#797b7d" />
            </Link>

            <div className="md:hidden space-x-1 ">
              <ul className="flex flex-row-reverse items-center">
                <li>
                  <button
                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                    onClick={() => setNavbar(!navbar)}
                  >
                    {navbar ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-black"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    )}
                  </button>
                </li>
                {otherLocales.map((locale) => {
                  const { pathname, query, asPath } = router;
                  return (
                    <li
                      key={locale}
                      onClick={onLanguageButtonClick}
                      className="float-left p-2 mx-2 text-white  hover:text-brand-100"
                    >
                      <Link
                        href={{ pathname, query }}
                        as={asPath}
                        locale={locale}
                        legacyBehavior
                        className="py-3 px-4"
                      >
                        {locale === "ar" ? "العربية" : "EN"}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-white hover:text-brand-100 mx-2">
                <Link href={`/${activeLocale}`} scroll={false}>
                  {__T("Home")}
                </Link>
              </li>
              <li className="text-white hover:text-brand-100 mx-2">
                <Link href={`/#features`} scroll={false}>
                  {__T("Our Features")}
                </Link>
              </li>
              {/* <li className="text-white hover:text-brand-100 mx-2">
                                <Link href={`/#chooseUs`} scroll={false}>{__T('Choose us')}</Link>
                            </li> */}
              <li className="text-white hover:text-brand-10 mx-2">
                <Link href={`/#pricing`} scroll={false}>
                  {__T("Pricing")}
                </Link>
              </li>
              <li className="text-white hover:text-brand-100 mx-2">
                <Link href={`/${activeLocale}/faq`}>{__T("FAQ")}</Link>
              </li>
              {/* <li className="text-white hover:text-indigo-200">
                                <a href="#">Contact US</a>
                            </li> */}
            </ul>

            <div className="mt-3 space-y-2 lg:hidden md:inline-block">
              <Link
                href={`/${activeLocale}/service-van`}
                className="inline-block w-full px-4 py-2 text-center text-white bg-brand-100 rounded-md shadow hover:bg-gray-800"
              >
                {/* <TruckIconSVG className="ltr:mr-1 rtl:mr-1 inline-block text-white w-8 relative -top-0.5" /> */}
                <span className="font-bold">{__T("Service Vans")}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden space-x-1 md:inline-block ">
          <ul className="flex flex-row-reverse items-center">
            <li className="float-left  mr-1">
              <Link
                className="py-3 px-4 bg-brand-100 rounded-4xl text-white mt-2 text-md flex-nowrap"
                href={`/${activeLocale}/service-van`}
              >
                <span className="font-bold">{__T("Service Vans")}</span>
              </Link>
            </li>

            {otherLocales.map((locale) => {
              const { pathname, query, asPath } = router;
              return (
                <li
                  key={locale}
                  onClick={onLanguageButtonClick}
                  className="float-left mx-2 p-2 text-white  hover:text-brand-100"
                >
                  <Link
                    href={{ pathname, query }}
                    as={asPath}
                    locale={locale}
                    legacyBehavior
                    className="py-3 px-4"
                  >
                    {locale === "ar" ? "العربية" : "EN"}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
